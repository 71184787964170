<!-- <div class="" style="margin-top: 15px">
  <div class="row">
    <div class="col-md-12 mx-auto">
      <div class="nk-pps-apps">
        <div class="nk-pps-title text-center">
          <h3 class="title">Withdraw Funds</h3>
        </div>
        <form class="nk-pps-form" (ngSubmit)="withdraw(so)" #so="ngForm" *ngIf="acc > 5" id="preact-wd-from">
          <div class="nk-pps-field form-group">
            <select name="withdrawto" required="" ngModel class="form-control form-control-lg" id="currencyfrr">
              <option value="" selected disabled>
                --Select payment address--
              </option>
              <option *ngIf="bitad && bitad != 'Not set'" value="Bitcoin" selected="">
                Bitcoin : <span *ngIf="bitad">{{ bitad }}</span>
              </option>

              <option *ngIf="usdt && usdt != 'Not set'" value="USDT(BEP20)" selected="">
                USDT(BEP20) : <span *ngIf="usdt">{{ usdt }}</span>
              </option>

              <option *ngIf="usdterc && usdterc != 'Not set'" value="USDT(ERC20)" selected="">
                USDT(ERC20) : <span *ngIf="usdterc">{{ usdterc }}</span>
              </option>

              <option *ngIf="usdttrc && usdttrc != 'Not set'" value="USDT(TRC20)" selected="">
                USDT(TRC20) : <span *ngIf="usdttrc">{{ usdttrc }}</span>
              </option>

              <option value="Bank" *ngIf="country == 'India' && bank && bank != 'Not set'">
                Bank : <span *ngIf="bank">{{ bank }}</span>
              </option>

              <option value="UPI" *ngIf="
                  country == 'India' && upi && upi != 'Not set' && upis == '1'
                ">
                UPI :
                <span *ngIf="upi">ID: {{ upi }}, NAME: {{ upname }}</span>
              </option>
            </select>
          </div>
          <div class="nk-pps-field-set">
            <div class="nk-pps-field-row row gy-gs">
              <div class="nk-pps-field-col col-12 col-sm-12 wdm-account-fmsa">
                <div class="nk-pps-field form-group">
                  <div class="form-label-group">
                    <label class="form-label" for="wdm-amount-from">Withdraw Amount</label>
                  </div>
                  <div class="form-control-group">
                    <div class="form-text-hint">
                      <span class="overline-title">USD</span>
                    </div>
                    <input type="text" class="form-control form-control-lg form-control-number wd-amount"
                      id="wdm-amount-from" required ngModel [(ngModel)]="val" placeholder="1,000.00" required ngModel
                      name="withdraw">
                  </div>
                  <div class="form-note-group">
                    <span class="nk-pps-bal form-note-alt">Current Balance: <strong class="text-base amount">{{ acc |
                        number }}
                        USD</strong></span>
                  </div>


                  <div class="no-fund mb-3" *ngIf="trust_access == 1">
                    <div class="no-fund alert alert-primary">
                      <span style="color: red">x</span>
                      Withdrawals of $1000 and above is processed by our partner wallet
                      <a href="xenhold.com">Xenhold.com</a>, Open an account to complete
                      the withdrawal process
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="nk-pps-field form-action text-center">
            <div class="nk-pps-action">
              <button type="submit" class="btn btn-lg btn-block btn-primary pps-btn-action" id="preact-wd"
                [disabled]="!so.valid">
                <span>Continue to Withdraw</span>
              </button>
            </div>
          </div>
        </form>


        <div class="container-fluid no-fund" *ngIf="acc < 5">
          <div class="no-fund alert alert-danger">
            <span style="color: red">x</span>
            You don't have enough funds to withdraw
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->








<div class="container">
  <div class="col-xl-12 col-lg-12 col-md-12">
    <div class="card acc_balance">
      <div class="card-header">
        <h4 class="card-title">Wallet</h4>
      </div>
      <div class="card-body">


        <div class="d-flex justify-content-between my-4">
          <div>
            <p class="mb-1">Available Balance</p>
            <h4 class="crypt-up" *ngIf="acc < 1"><b>$0.00 USD </b></h4>
            <h4 class="crypt-up" *ngIf="acc > 1"><b>{{acc | currency}}</b></h4>
          </div>
          <div>
            <p class="mb-1">Pending</p>
            <h4>$ {{pwith}}</h4>
          </div>
        </div>


        <div class="no-fund mb-3" *ngIf="trust_access == 1">
          <div class="no-fund alert alert-primary">
            <span style="color: red">x</span>
            Withdrawals of $1000 and above is processed by our partner wallet
            <a href="xenhold.com">Xenhold.com</a>, Open an account to complete
            the withdrawal process
          </div>
        </div>



        <div class="container-fluid" *ngIf="display == 1">
          <div class="crypt-withdraw-body bg-white">
            <form (ngSubmit)='withdraw(so)' #so='ngForm'>
              <div class="row">
                <div class="col-md-12">



                  <div class="input-group mb-3">
                    <input type="text" class="form-control" required ngModel [(ngModel)]='val' placeholder="1,000.00"
                      required ngModel name="withdraw">
                    <div class="input-group-append">
                      <span class="input-group-text">USD</span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-group">
                      <select name="withdrawto" required="" ngModel class="form-control" id="currencyfrr">
                        <option value="" selected disabled>--Select payment address--</option>
                        <option *ngIf="bitad && bitad != 'Not set'" value="Bitcoin" selected="">
                          Bitcoin : <span *ngIf="bitad">{{ bitad }}</span>
                        </option>

                        <option *ngIf="usdt && usdt != 'Not set'" value="USDT(BEP20)" selected="">
                          USDT(BEP20) : <span *ngIf="usdt">{{ usdt }}</span>
                        </option>

                        <option *ngIf="usdterc && usdterc != 'Not set'" value="USDT(ERC20)" selected="">
                          USDT(ERC20) : <span *ngIf="usdterc">{{ usdterc }}</span>
                        </option>

                        <option *ngIf="usdttrc && usdttrc != 'Not set'" value="USDT(TRC20)" selected="">
                          USDT(TRC20) : <span *ngIf="usdttrc">{{ usdttrc }}</span>
                        </option>

                        <option value="Bank" *ngIf="country == 'India' && bank && bank != 'Not set'">
                          Bank : <span *ngIf="bank">{{ bank }}</span>
                        </option>

                        <option value="UPI" *ngIf="
                            country == 'India' && upi && upi != 'Not set' && upis == '1'
                          ">
                          UPI :
                          <span *ngIf="upi">ID: {{ upi }}, NAME: {{ upname }}</span>
                        </option>

                      </select>
                    </div>
                  </div>

                  <button [disabled]='!so.valid' class="crypt-button-red-full" *ngIf='acc >= withlimit'>Initiate
                    Withdraw</button>

                  <div class="no-fund alert alert-danger" *ngIf="acc < withlimit">
                    <span style="color:red">x</span>
                    You don't have enough funds to withdraw
                  </div>


                </div>
              </div>
            </form>


          </div>
        </div>




        <div class="btn-group mb-3 mt-3">
          <button class="btn btn-danger waves-effect" (click)="display  = 1" *ngIf="display == 0">Withdraw</button>
          <button class="btn btn-success waves-effect" routerLink="../profile" *ngIf=" !bitad ">Update Wallet
            Address</button>
        </div>
      </div>
    </div>
  </div>
</div>
