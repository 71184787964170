<div class="container-fluid">
  <div class="header">
    <h1 class="header-title">Citizenship by investment</h1>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <p class="card-title mb-0">Offers</p>
          <br />
          <div class="grid-body text-center">
            <div class="card-body item-wrapper">
              <div>

              </div>
              <h4 class="text-center" style="font-weight: bolder;">Unlock Your Global Opportunities through Citizenship by
                Investment in Real
                Estate
              </h4>

              <p>Citizenship by investment programs (CIPs) allow individuals to acquire citizenship or residency in tnited
                State
                by
                making a significant investment in it's economy.</p>


              <p>
                We would like to draw your attention to the extraordinary prospects offered by investing in real estate as a
                means to obtain citizenship. United State as a progressive nation has recognized the immense value of
                attracting global
                investors by granting them the privilege of citizenship in exchange for their substantial investment in the
                United State's real estate market. This forward-thinking approach has proven to be a win-win scenario for both
                investors and host country.
              </p>

              <p>Acquiring citizenship in the United state presents you with the incredible advantage of unrestricted global
                mobility. You gain the freedom to travel visa-free or with relaxed visa requirements to an extensive network
                of countries, facilitating hassle-free business ventures, international collaborations, and leisurely
                exploration.</p>


              <p>As a citizen of U.S.A, you gain access to a wide range of business and investment opportunities. You
                can tap into new markets, establish branches or subsidiaries, and enjoy favorable tax regimes that promote
                entrepreneurship and wealth creation.</p>

              <p>Investing in real estate is an excellent strategy for wealth preservation and growth. By owning property in
                U.S.A and generate rental income, thereby safeguarding your financial future</p>

              <h5 style="font-weight: bolder;" class="text-center">Firstradeonline as a global investment company in Real-Estate
              </h5>

              <p> Firstradeonline as a registered company in the United State provides this oppoutunity to you hassle-free, we
                provide you
                diffrent opportunities and packages to tap into the current real-estate market in turn giving you access to
                the citizenship grant and also an opportunity to own your personal real estate property of your choosing in
                less than 4 years, All investments are risk free, fully funded and capital refundable</p>

              <h6 style="font-weight: bolder;">Packages</h6>

              1) 10,000 CIPs funding grants you access to 500% pure profit, Green/Visa card application proceeds and the
              opportunity to own your
              real estate property after 4 years.
              <br>
              <br>
              2) 20,000 CIPs funding grants you access to 500% pure profit, Green/Visa card application proceeds and the
              opportunity to own your
              real estate property after 3 years.
              <br>
              <br>
              3) 30,000 CIPs funding grants you access to 500% pure profit, Green/Visa card application proceeds and the
              opportunity to own your
              real estate property after 2 years.
              <br>
              <br>
              4) 50,000 CIPs funding grants you access to 500% pure profit, Green/Visa card application proceeds and the
              opportunity to own your
              real estate property after 1 year.
              <br>
              <br>
              <button type="button" class="w-100 btn btn-primary" [routerLink]="['../deposit']"
                [queryParams]="{ dep: 'invest' }">Proceed</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
